.classPrompt .el-message-box__container {
  color: red;
}
.bookkeeping-table-status.item_icon {
  display: inline-block;
  padding: 1px 3px;
  cursor: pointer;
}
.bookkeeping-table-status.item_icon i {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 2px;
}
.bookkeeping-table-status.item_icon .iconfont icon-cuowu {
  color: #f56c6c;
}
.bookkeeping-table-status.item_icon .iconfont icon-duihao {
  color: #67c23a;
}
.bookkeeping-table-status.item_icon .el-icon-circle-check {
  color: #67c23a;
}
.bookkeeping-table-status.item_icon .iconfont icon-info {
  color: #409eff;
}
.bookkeeping-table-status.item_icon .iconfont icon-cuowu {
  color: #e6a23c;
}
.bookkeeping-table-status.item_icon .iconfont icon-cuowu-outline {
  color: #e6a23c;
}
.bookkeeping-table-status.item_icon p {
  display: inline-block;
  font-size: 13px;
  color: #333;
}