.sort-box[data-v-02b7f94c] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.sort-box > span[data-v-02b7f94c]:last-of-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
}
.sort-box .sort-caret[data-v-02b7f94c] {
  width: 0;
  height: 0;
  border: solid 5px transparent;
}
.sort-box .sort-caret.descending[data-v-02b7f94c] {
  border-top-color: #fff;
}
.sort-box .ascending[data-v-02b7f94c] {
  border-bottom-color: #fff;
}