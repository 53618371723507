.home[data-v-7f397510] {
  padding: 10px;
}
.home .header[data-v-7f397510] {
  margin-bottom: 10px;
}
.home .header .right[data-v-7f397510] {
  float: right;
}
.home .header .input-search[data-v-7f397510] {
  width: 180px;
  margin-right: 5px;
}
.home .img-ewm[data-v-7f397510] {
  width: 50px;
}
.pagination[data-v-7f397510] {
  text-align: right;
  margin-right: 26px;
  margin-top: 16px;
}
.styleForm .el-select[data-v-7f397510] {
  margin: 0px;
}