.popverMain[data-v-5b8d1472] {
  cursor: pointer;
}
.el-dropdown-menu--small[data-v-5b8d1472] .el-dropdown-menu__item {
  padding: 2px 6px !important;
}
.circle[data-v-5b8d1472] {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}
.small_cell div[data-v-5b8d1472] {
  padding-left: 2px !important;
  padding-right: 0 !important;
}
.right_box2[data-v-5b8d1472] {
  position: absolute;
  right: 19px;
  top: 11px;
  left: 402px;
}
.right_box3[data-v-5b8d1472] {
  position: absolute;
  right: 19px;
  top: 15px;
  left: 400px;
}
.right_box[data-v-5b8d1472] {
  position: absolute;
  right: 19px;
  top: 13px;
}
.right_box1[data-v-5b8d1472] {
  float: right;
  width: 21%;
  text-align: right;
  margin-bottom: 5px;
}
[data-v-5b8d1472] textarea {
  min-height: 145px !important;
}
.none-border p[data-v-5b8d1472] {
  line-height: 30px;
  text-align: left;
  text-indent: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.right_title[data-v-5b8d1472] {
  width: 98%;
  margin: 0 auto;
  margin-bottom: 10px;
}
.right_title h3[data-v-5b8d1472] {
  font-size: 14px;
  padding-bottom: 10px;
}
.right_title h5[data-v-5b8d1472] {
  font-weight: normal;
}
.right_title p[data-v-5b8d1472] {
  font-size: 12px;
  color: red !important;
  text-align: left !important;
  padding: 5px;
}